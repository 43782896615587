import React, { useState } from 'react';
import Data from './Data';

const Slide = ({ slides }) => {
  const [current, setCurrent] = useState(0)
  const length = slides.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  if(!Array.isArray(slides) || slides.length <= 0 ) {
    return null
  }
  return (
    <>
      <section className='slider h-full'>
        <div className='control-btn'>
          <button className='prev' onClick={prevSlide}>
            <i className='fas fa-caret-left'></i>
          </button>
          <button className='next' onClick={nextSlide}>
            <i className='fas fa-caret-right'></i>
          </button>
        </div>
      
        {Data.map((slide, index) => {
          return(
            <section>
              <div className={index === current ? "slide active" : "slide" } key={index}>
                {index === current && <img src={slide.images} alt="Home" /> }
              </div>
              <div className={index === current ? "absolute" : "slide" } key={index}>
                {index === current && <div className="text-white text-lg font-semibold my-2 top-50">{slide.message}</div> }
              </div>
            </section>
          )
        })}
        <div className='absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay'></div>
      </section>

      {/* <section className='slide-form'>
        <div className='container'>
          <h2>Enjoy Your Stay</h2>
          <span>Search and Book Hotel</span>

          <form action=''>
            <input type="text" placeholder='Search City' name='' id='' />
            <div className='flex_space'>
              <input type="date" placeholder='Check In' />
              <input type="date" placeholder='Check Out' />
            </div>
            <div className='flex_space'>
              <input type="number" placeholder='Adult(s)(18+)' />
              <input type="number" placeholder='Children(0 - 17)' />
            </div>
            <input type="number" placeholder='Rooms' />
            <input type="Submit" value="Search" className='submit' />
          </form>
        </div>
      </section> */}
    </>
  )
}

export default Slide