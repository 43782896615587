import React from 'react';
import Hero from "../components/HomeSection/Slides/Hero"
import Scale from "../components/HomeSection/Scale/Scale"
// const Scale = React.lazy(() => import("../components/HomeSection/Scale/Scale"));

// import { Link, Route, Routes } from "react-router-dom";

const Home = () => {
    return (
        <>
            <Hero />
            <Scale />
        </>
    );
};

export default Home;