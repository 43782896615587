import React from 'react';

const About = () => {
    return (
        <div className='relative flex flex-col items-center top-0 right-0 left-0 bottom-0 h-screen bg-blackOverlay'>
            <h2 className="text-3xl font-semibold font-mono  py-2">About Us</h2>
            <p >About Ideas Computer Services</p>
        </div>
    );
};

export default About;