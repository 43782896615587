import React from 'react';

const Service = ({ title, image, description }) => {
    return (
        <div className="bg-white shadow p-4 rounded-md">
            <img src={image} alt="Service offered" width={130} />
            <h3 className="text-lg font-semibold my-2">{title}</h3>
            <p>{description}</p>
        </div>
    );
};

export default Service;