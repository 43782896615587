
const Data = [
    {
        images: "/images/slide-1c.jpg",
        message: "test1",
    },
    {
        images: "/images/slide-2c.jpg",
        message: "test2",
    },
    // {
    //     images: "/images/slide-3.jpg",
    // },
    {
        images: "/images/slide-4c.jpg",
        message: "test3",
    }
]

export default Data