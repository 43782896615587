import React from 'react';
import Scale from "./HomeSection/Scale/Scale"

const Service = () => {
    return (
        <>
            <section className='h-screen'>
            {/* <h2 className="text-3xl font-semibold  py-2">Services</h2> */}
                <Scale />
            </section>
        </>
    );
};

export default Service;