import React from 'react'
import Service from './Service';

const services = [
  { title: 'DeVops and Automation', image: "/images/automation.png", description: 'We intoduce your infrastructure into a DevOps culture, reducing human interference and speeding up productivity from development to production.' },
  { title: 'Cloud and Application Security', image: "/images/cloud-sec.png", description: "Cloud and Application security, or appsec, help maintain all security best practicies." },
  { title: 'Scalable and Load Tolerance Infrastructures', image: "/images/server.png", description: 'Building highly available infrastructure that are faut tolerance' },
  { title: 'Observability', image: "/images/logging.png", description: 'Event driven infrastructure to ensure a very responsive environment' },
  { title: 'Cloud Cost management', image: "/images/cost.png", description: 'We continually evaluate infrastructural usage and capacity allocation to maximise resources and reduce cost of operations' },
  { title: 'Architectural and Well Documented Designs', image: "/images/arch.png", description: 'In a fast changing cloud space, our team continue to evaluate latest cloud offerrings and making them available as an option to our customers, and clients as needed.' },
  { title: 'Migration', image: "/images/migration.png", description: 'Compute, network, security, and data migration.' },
];

const Scale = () => {
  return (
    <>
      <section className='relative'>
        <div className='w-full object-cover'>
          <img src="/images/scaling.jpg" alt="Scale" />
        </div>
        {/* <div className='absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay'></div> */}
        <div className="absolute overflow-auto justify-center top-0 right-0 left-0 bottom-0 mt-5 mx-3">
            <h2 className="text-xl text-white font-semibold mb-2">Explore Our Services</h2>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-5">
                {services.map((service, index) => (
                    <div className=''>
                      <Service key={index} title={service.title} image={service.image} description={service.description} />
                    </div>
                ))}
            </div>
        </div>
      </section>
    </>
  )
}

export default Scale;