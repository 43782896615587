import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
// import './App.css'; // Add custom styles if needed
import Header from './containers/Header';
import Home from './containers/Home';
import About from './components/About';
import Contact from './components/Contact';
import Service from './components/Services';
// import Login from './components/Login';

const App = () => {
    return (
      <Routes>
        <Route element={<Header />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Service />} />
        </Route>
      </Routes>
    );
};

export default App;
