import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";

// import logo from '../assets/ideas_logo.png';
// import logo_cart from '../assets/cart.png';

const Header = () => {
    const [click, setClick] = useState(false)
    
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    return (
        <>
            <nav className='z-10 h-1/4 leading-10 sticky top-0 shadow-md md:text-center'>
                <div className="bg-mainTest flex justify-between ">
                    <div className="hidden md:block md:absolute md:top-0 md:left-5 md:text-3xl md:cursor-pointer md:text-white active:md:text-black" onClick={handleClick}>
                        <i className= {click ? "fas fa-times md:text-3xl" : "fas fa-bars"}></i>
                    </div>
                    <ul className= {click ? "md:w-full md:h-90vh md:absolute md:bg-naveColor md:transition-all md:active:duration-5 md:ease-in  md:top-[47px] md:active:left-0 md:opacity-80 md:active:z-auto" : "flex justify-between items-center "}>
                        <li className='mx-8 transition-all duration-5 ease-in md:block'>
                            <Link to="/" onClick={closeMobileMenu}>
                                <div className='flex justify-center items-center text-naveColor'>
                                    {/* <div>
                                        <i className="far fa-lightbulb mx-1"></i>
                                    </div> */}
                                    <div className='md:hidden'>
                                        <h4 className='text-2xl mx-1 text-naveColor'>atlas+</h4>
                                    </div>
                                </div>
                                {/* <img src={logo} width='130px' alt="Ideas computer services homepage with a light bulb that signifies a new idea (Lightbulb moment)" /> */}
                            </Link>
                        </li>
                        <li className='mx-8 md:my-4 transition-all duration-5 ease-in md:block'>
                            <Link to="/" className='hover:text-naveColor text-white text-15 font-thin md:font-normal tracking-2 transition-all duration-5 ease-in-out opacity-80' onClick={closeMobileMenu}>Home</Link>
                        </li>
                        <li className='mx-8 md:my-4 transition-all duration-5 ease-in md:block'>
                            <Link to="/services" className='hover:text-naveColor text-white text-15 font-thin md:font-normal tracking-2 transition-all duration-5 ease-in-out opacity-80' onClick={closeMobileMenu}>Services</Link>
                        </li>
                        <li className='mx-8 md:my-4 transition-all duration-5 ease-in md:block'>
                            <Link to="/about" className='hover:text-naveColor text-white text-15 font-thin md:font-normal tracking-2 transition-all duration-5 ease-in-out opacity-80' onClick={closeMobileMenu}>About</Link>
                        </li>
                        <li className='mx-8 md:my-4 transition-all duration-5 ease-in md:block'>
                            <Link to="/contact" className='hover:text-naveColor text-white text-15 font-thin md:font-normal tracking-2 transition-all duration-5 ease-in-out opacity-80' onClick={closeMobileMenu}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <div>
                        <ul className='flex text-white justify-between items-center'>
                        <li className='mx-8 transition-all duration-5 ease-in'>
                            <Link to="/" className='hover:text-naveColor text-white text-15 font-thin md:font-normal tracking-2 transition-all duration-5 ease-in-out opacity-80'>
                                <i className='fas fa-sign-in-alt'> Login</i>
                            </Link>
                        </li>
                        {/* <li  className='mx-8 transition-all duration-5 ease-in'>
                            <Link to="/signup">
                                <i className='fas fa-sign-out-alt'>Sign Up</i>
                            </Link>
                        </li> */}
                        <li  className='md:hidden mx-8 transition-all duration-5 ease-in'>
                            <Link to="/contact">
                                <button className='primary-btn' onClick={closeMobileMenu}>Contact Us</button>
                            </Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
            <footer className="bg-gray-600" >
                <div className="flex text-white py-4 flex-col justify-between items-center">
                    <span>&copy; Atlas Plus Consulting 2023. All Rights Reserved.</span>
                </div>
            </footer>
        </>
    );
};

export default Header;